/**
 * 村社发展治理接口管理
 */
import service from '../request'

const baseUrl = window.globalUrl.HOME_API;

// 获取发展治理列表
export const getFileLists = (params) => {
  // return service.post(`${baseUrl}/file/downZone`, params)
  return service({
    method: 'get',
    url: `${baseUrl}/cms/article/page`,
    params: params
  })
}

// 下载文件
export const downloadFile = (params) => {
  // return service.post(`${baseUrl}/file/downZone`, params)
  return service({
    method: 'post',
    url: `${baseUrl}/cms/file/downDevelop`,
    data: params
  })
}

// 获取新闻的详情
export const getNewsDetail = (params) => {
  return service.get(`${baseUrl}/admin/cms/getById`, {params})
}

// 获取带有标题图片的前十条新闻
export const getRecentNews = (params) => {
  // return service.post(`${baseUrl}/file/downZone`, params)
  return service({
    method: 'get',
    url: `${baseUrl}/cms/article/imgArticle`,
    params: params
  })
}

// 获取最近的新闻
export const getNewsLists = (params) => {
  // return service.post(`${baseUrl}/file/downZone`, params)
  return service({
    method: 'get',
    url: `${baseUrl}/cms/article/page`,
    params: params,
    isForm: true
  })
}
